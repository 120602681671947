// Generated by Framer (44d7d2d)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as sharedStyle from "../css/u6aCJGBP4";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["q37mtnaKa"];

const serializationHash = "framer-m9myh"

const variantClassNames = {q37mtnaKa: "framer-v-y5j8v7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, RAnaZ75QL: title ?? props.RAnaZ75QL ?? "Web Design"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, RAnaZ75QL, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "q37mtnaKa", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-y5j8v7", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"q37mtnaKa"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-8j0r14-container"} layoutDependency={layoutDependency} layoutId={"rGcc4J2CG-container"}><Phosphor color={"var(--token-4c2d4390-2a5c-44d9-8c88-e13922773f89, rgb(156, 255, 51))"} height={"100%"} iconSearch={"House"} iconSelection={"StarFour"} id={"rGcc4J2CG"} layoutId={"rGcc4J2CG"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-kld0xz"} data-styles-preset={"u6aCJGBP4"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-ea2baa85-1cd1-4f09-9415-e302f0fcfa08, rgb(204, 204, 204)))"}}>Web Design</motion.p></React.Fragment>} className={"framer-ixmq6k"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"HmigyBXob"} style={{"--extracted-r6o4lv": "var(--token-ea2baa85-1cd1-4f09-9415-e302f0fcfa08, rgb(204, 204, 204))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={RAnaZ75QL} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-m9myh.framer-1a57fbp, .framer-m9myh .framer-1a57fbp { display: block; }", ".framer-m9myh.framer-y5j8v7 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 22px; justify-content: center; overflow: visible; padding: 0px 20px 0px 20px; position: relative; width: min-content; }", ".framer-m9myh .framer-8j0r14-container { flex: none; height: 14px; left: -7px; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; z-index: 1; }", ".framer-m9myh .framer-ixmq6k { flex: none; height: 16px; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-m9myh.framer-y5j8v7 { gap: 0px; } .framer-m9myh.framer-y5j8v7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-m9myh.framer-y5j8v7 > :first-child { margin-left: 0px; } .framer-m9myh.framer-y5j8v7 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 118
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerVariables {"RAnaZ75QL":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerjlvs4fmN1: React.ComponentType<Props> = withCSS(Component, css, "framer-m9myh") as typeof Component;
export default Framerjlvs4fmN1;

Framerjlvs4fmN1.displayName = "Top Ticker Item";

Framerjlvs4fmN1.defaultProps = {height: 22, width: 118};

addPropertyControls(Framerjlvs4fmN1, {RAnaZ75QL: {defaultValue: "Web Design", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerjlvs4fmN1, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQ bJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PhosphorFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})